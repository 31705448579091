import { useRef } from "react";

const useLocales = () => {
  const localesRef = useRef(null);
  const setLocalesRef = useRef((value) => {
    localesRef.current = value;

    localStorage.setItem("locales", value);

    const cookieExpires = new Date();
    cookieExpires.setMonth(cookieExpires.getMonth() + 12);
    document.cookie = `language=${value}; expires=${cookieExpires}; domain=.ecards.cab`;
  });

  if (localesRef.current === null) {
    const storageLocales = localStorage.getItem("locales");

    if (storageLocales) {
      setLocalesRef.current(storageLocales);
    } else {
      setLocalesRef.current("en");
    }
  }

  return [localesRef.current, setLocalesRef.current];
};

export default useLocales;
